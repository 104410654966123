import { useMainStore } from "./store/main";
import PointSCommon from "@points/common";
import { createPinia, PiniaVuePlugin } from "pinia";
import Vue, { defineComponent, getCurrentScope } from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import { createRouter } from "./router/router";
// This gets imported into the global space and must be imported last to override other styles
import "./styles/app.scss";
import { globals } from "./utilities/globals";
import VueShortkey from "vue-shortkey";
import axios from "axios";
axios.defaults.baseURL = `${process.env.VUE_APP_CENTRAL}/api/`;
Vue.config.productionTip = false;
Vue.config.devtools = !!process.env.VUE_APP_DEVTOOLS;
// eslint-disable-next-line @typescript-eslint/unbound-method -- Cannot fix the signature of this Vue internal method
const originalHandler = Vue.config.warnHandler;
Vue.config.warnHandler = (msg, vm, trace) => {
    // Swallow invalid Bryntum vue usage warnings
    if (msg.startsWith(`Avoid using variables that start with _ or $ in setup()`))
        return;
    originalHandler?.(msg, vm, trace);
};
// Register pinia
Vue.use(PiniaVuePlugin);
const pinia = createPinia();
// Create router (depends on pinia)
Vue.use(VueRouter);
const router = createRouter();
// Register common config/handler/components (depends on pinia and router)
Vue.use(PointSCommon, {
    ...globals,
    pinia,
    router
});
Vue.use(VueShortkey);
// Make scope available to options api
Vue.mixin(defineComponent({
    setup() {
        return {
            scope: getCurrentScope()
        };
    }
}));
async function initApp() {
    try {
        await useMainStore(pinia).loadWholesaleCompany();
        new Vue({
            router,
            pinia,
            vuetify,
            render: (h) => h(App)
        }).$mount("#app");
    }
    catch (error) {
        const errorMessage = document.createElement("div");
        errorMessage.innerHTML = "Error contacting server, please try again later.";
        document.getElementById("app")?.firstChild?.replaceWith(errorMessage);
        throw error;
    }
}
const { hostname, href, protocol } = location;
if (hostname === "localhost") {
    void initApp();
}
else {
    if (hostname.includes("ndtirepartner.com"))
        location.replace("https://www.tfdistro.com");
    const isApex = hostname.split(".")[1] === "com";
    const isHttp = protocol === "http:";
    if (isApex && isHttp)
        location.replace(href.replace("http://", "https://www."));
    else if (isApex)
        location.replace(href.replace("https://", "https://www."));
    else if (isHttp)
        location.replace(href.replace("http://", "https://"));
    else
        void initApp();
}
