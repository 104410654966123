import { useMainStore } from "../store/main";
import { useOidcStore } from "@points/common";
export function isInRole(role) {
    if (role === undefined)
        return true;
    const oidcStore = useOidcStore();
    const mainStore = useMainStore();
    const roles = oidcStore.roles;
    const b2bRoles = mainStore.wholesaleUser?.roles ?? [];
    if (typeof role === "string") {
        return roles.concat(b2bRoles).includes(role);
    }
    else {
        return role.some((r) => roles.concat(b2bRoles).includes(r));
    }
}
