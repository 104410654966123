const missing = [];
if (!process.env.VUE_APP_SUPPORTPHONE)
    missing.push("VUE_APP_SUPPORTPHONE");
if (!process.env.VUE_APP_CENTRAL)
    missing.push("VUE_APP_CENTRAL");
if (!process.env.VUE_APP_PATH)
    missing.push("VUE_APP_PATH");
if (!process.env.BASE_URL)
    missing.push("BASE_URL");
if (!process.env.NODE_ENV)
    missing.push("NODE_ENV");
if (!process.env.VUE_APP_OIDC_CLIENT_NAME)
    missing.push("VUE_APP_OIDC_CLIENT_NAME");
if (!process.env.VUE_APP_B2B_BLOB)
    missing.push("VUE_APP_B2B_BLOB");
if (missing.length && process.env.NODE_ENV !== "test")
    throw new Error(`Missing required environment settings: ${missing.join(", ")}`);
export const globals = Object.freeze({
    VUE_APP_APPLICATIONINSIGHTS: process.env.VUE_APP_APPLICATIONINSIGHTS ?? "",
    VUE_APP_SUPPORTPHONE: process.env.VUE_APP_SUPPORTPHONE ?? "",
    VUE_APP_BUILD_ID: process.env.VUE_APP_BUILD_ID ?? "",
    VUE_APP_AUTHORITY: process.env.NODE_ENV !== "production"
        // Use specified auth url when not in prod
        ? process.env.VUE_APP_AUTHORITY
        : window.location.host.includes("www")
            // Use subdomain seperator for www hosts
            ? `${window.location.protocol}//accounts.${window.location.host.slice(4)}${process.env.BASE_URL}`
            : window.location.host.includes("foxs")
                // Use subdomain seperator for subdomain hosts
                ? `${window.location.protocol}//accounts.${window.location.host.slice(5)}${process.env.BASE_URL}`
                // Use dash seperator for non-www hosts
                : `${window.location.protocol}//accounts-${window.location.host}${process.env.BASE_URL}`,
    VUE_APP_CENTRAL: process.env.VUE_APP_CENTRAL ?? "",
    BASE_URL: process.env.BASE_URL ?? "",
    VUE_APP_PATH: process.env.VUE_APP_PATH ?? "",
    VUE_APP_NAME: process.env.VUE_APP_NAME ?? "VUE_APP_NAME_NOT_FOUND",
    VUE_APP_ENVIRONMENT: process.env.VUE_APP_ENVIRONMENT ?? "VUE_APP_ENVIRONMENT_NOT_FOUND",
    VUE_APP_VERSION: process.env.VUE_APP_VERSION ?? "VUE_APP_VERSION_NOT_FOUND",
    NODE_ENV: process.env.NODE_ENV ?? "",
    BUILD_ID: process.env.BUILD_ID ?? "local",
    VUE_APP_DEVTOOLS: process.env.VUE_APP_DEVTOOLS ?? "",
    VUE_APP_OIDC_CLIENT_NAME: process.env.VUE_APP_OIDC_CLIENT_NAME ?? "VUE_APP_OIDC_CLIENT_NAME_NOT_FOUND",
    VUE_APP_B2B_BLOB: process.env.VUE_APP_B2B_BLOB ?? ""
});
