import { globals } from "../utilities/globals";
import axios from "axios";
import { compare } from "fast-json-patch";
export async function getUsers(queryOptions) {
    const response = await axios.get(`${globals.VUE_APP_CENTRAL}/b2b/api/users`, {
        params: queryOptions
    });
    return response.data.items;
}
export async function getCurrentUser() {
    const response = await axios.get(`${globals.VUE_APP_CENTRAL}/b2b/api/users/me`);
    return response.data;
}
export async function getUser(id) {
    if (!id) {
        throw new Error("id is required");
    }
    const response = await axios.get(`${globals.VUE_APP_CENTRAL}/b2b/api/users/${id}`);
    return response.data;
}
export async function postUser(user) {
    const response = await axios.post(`${globals.VUE_APP_CENTRAL}/b2b/api/users`, user);
    return response.data;
}
export async function patchUser(oldUser, newUser) {
    oldUser.roles?.sort();
    newUser.roles?.sort();
    const patchDocument = compare(oldUser, newUser);
    const response = await axios.patch(`${globals.VUE_APP_CENTRAL}/b2b/api/users/${newUser.id}`, patchDocument);
    return response.data;
}
export async function deleteUser(id) {
    if (!id) {
        throw new Error("id is required");
    }
    await axios.delete(`${globals.VUE_APP_CENTRAL}/b2b/api/users/${id}`);
}
