import { ShippingRouteTypeEnum } from "../models/ShippingRouteTypeEnum";
import { TimeZoneDate } from "@points/common";
/**
 * Return the start time for the b2b company on the specified date.
 * Will return time if it is defined.
 * @param b2bCompany - The company being checked
 * @param date - A date and time to check against
 * @param timeZone - The timezone of the b2b company: TODO - should be on the company or inventorylocation
 * @returns date and time at which the company closes on the day specified in the date param, or undefined if the company is not op
 */
export function startOfDay(b2bCompany, date) {
    let startTime;
    switch (date.getDayTZ()) {
        case 0:
            startTime = b2bCompany.sundayStartTime;
            break;
        case 1:
            startTime = b2bCompany.mondayStartTime;
            break;
        case 2:
            startTime = b2bCompany.tuesdayStartTime;
            break;
        case 3:
            startTime = b2bCompany.wednesdayStartTime;
            break;
        case 4:
            startTime = b2bCompany.thursdayStartTime;
            break;
        case 5:
            startTime = b2bCompany.fridayStartTime;
            break;
        case 6:
            startTime = b2bCompany.saturdayStartTime;
            break;
    }
    if (!startTime)
        return;
    return date.toTimeOfDayTZ(parseInt(startTime.split(":")[0]), parseInt(startTime.split(":")[1]));
}
/**
 * Return the end time for the current day specified for this b2b company.
 * Will return time if it is defined.
 * @param b2bCompany - The company being checked
 * @param date - A date and time to check against
 * @param timeZone - The timezone of the b2b company: TODO - should be on the company or inventorylocation
 * @returns date and time at which the company closes on the day specified in the date param, or undefined if the company is not op
 */
export function endOfDay(b2bCompany, date) {
    let endTime;
    switch (date.getDayTZ()) {
        case 0:
            endTime = b2bCompany.sundayEndTime;
            break;
        case 1:
            endTime = b2bCompany.mondayEndTime;
            break;
        case 2:
            endTime = b2bCompany.tuesdayEndTime;
            break;
        case 3:
            endTime = b2bCompany.wednesdayEndTime;
            break;
        case 4:
            endTime = b2bCompany.thursdayEndTime;
            break;
        case 5:
            endTime = b2bCompany.fridayEndTime;
            break;
        case 6:
            endTime = b2bCompany.saturdayEndTime;
            break;
    }
    if (!endTime)
        return;
    return date.toTimeOfDayTZ(parseInt(endTime.split(":")[0]), parseInt(endTime.split(":")[1]));
}
/**
 * Returns true if a store has closed for the day
 * @param b2bCompany - The company being checked
 * @param date - A date and time to check against
 * @param timeZone - The timezone of the b2b company: TODO - should be on the company or inventorylocation
 * @returns date and time at which the company closes on the day specified in the date param, or undefined if the company is not op
 */
export function isPastClosingTimeForDay(b2bCompany, date) {
    const endTime = endOfDay(b2bCompany, date);
    return !endTime || date >= endTime;
}
/**
* Returns the next time a location is open, starting at the specified date
* @param b2bCompany - The company being checked
* @param date - A date and time to check against
* @param timeZone - The timezone of the b2b company: TODO - should be on the company or inventorylocation
* @returns date and time at which the company is next open starting at the date param, undefined if the company is never open (probably bad data)
*/
export function nextOpenTime(b2bCompany, date) {
    const dateToCheck = new TimeZoneDate(date);
    let startOfDayResult = startOfDay(b2bCompany, dateToCheck);
    if (startOfDayResult && startOfDayResult >= dateToCheck)
        // Earliest pickup is at opening time on same day
        return startOfDayResult;
    const endOfDayResult = endOfDay(b2bCompany, dateToCheck);
    if (endOfDayResult && endOfDayResult >= dateToCheck)
        // Earliest pickup is at the time being check (now)
        return dateToCheck;
    for (let i = 0; i <= 7; i++) {
        // Add one day and check again
        dateToCheck.setDate(dateToCheck.getDate() + 1);
        startOfDayResult = startOfDay(b2bCompany, dateToCheck);
        // If the location is open on any following day, that is the time it will be available
        if (startOfDayResult)
            return startOfDayResult;
    }
    throw new Error(`B2bCompany ${b2bCompany.b2BCompanyId} does not define any open hours`);
}
/**
 * Format a cutoff date as Today, Tomorrow, or Short Weekday + h:mm A
 * TODO: use real timezone
 */
export function formatCutOffDate(route) {
    const cutOff = new TimeZoneDate(route.timeZone ?? "America/Los_Angeles", route.cutOffDateTimeUtc);
    const now = new TimeZoneDate(route.timeZone ?? "America/Los_Angeles");
    const options = {
        weekday: "short", hour: "numeric", minute: "numeric"
    };
    if (cutOff.getDayTZ() === now.getDayTZ())
        return `Today ${new Date(route.cutOffDateTimeUtc).toLocaleTimeString("en-us", { ...options, weekday: undefined })}`;
    if (cutOff.getDayTZ() === now.getDayTZ() + 1)
        return `Tomorrow ${new Date(route.cutOffDateTimeUtc).toLocaleTimeString("en-us", { ...options, weekday: undefined })}`;
    return new Date(route.cutOffDateTimeUtc).toLocaleDateString("en-us", options);
}
/**
 * Format pickup or shipment date to Now or Today/Tomorrow/Short Weekday + month/day
 */
export function formatShippingDate(route, b2bCompany, timePrefix) {
    const now = new TimeZoneDate(route.timeZone ?? "America/Los_Angeles");
    const tomorrow = new TimeZoneDate(now);
    tomorrow.setDate(now.getDate() + 1);
    const options = {
        weekday: "short", month: "numeric", day: "numeric"
    };
    if (route.routeType === ShippingRouteTypeEnum.WC) {
        /* Intl */
        const timeOptions = { hour: "numeric", minute: "numeric" };
        // Pickup has time attached to it
        const lastTransferDate = new TimeZoneDate(route.timeZone ?? "America/Los_Angeles", route.lastTransferArrivalDateTimeUtc);
        const nextPickupTime = nextOpenTime(b2bCompany, lastTransferDate);
        if (nextPickupTime <= now)
            return "Now";
        if (nextPickupTime.toDateStringTZ() === now.toDateStringTZ())
            return `Today ${timePrefix} ${nextPickupTime.toLocaleTimeString("en-us", timeOptions)}`;
        if (nextPickupTime.toDateStringTZ() === tomorrow.toDateStringTZ())
            return `Tomorrow ${timePrefix} ${nextPickupTime.toLocaleTimeString("en-us", timeOptions)}`;
        return `${nextPickupTime.toLocaleDateString("en-us", options)} ${timePrefix} ${nextPickupTime.toLocaleTimeString("en-us", timeOptions)}`;
    }
    else if (route.routeType === ShippingRouteTypeEnum.Route) {
        const shipDate = new TimeZoneDate(route.timeZone ?? "America/Los_Angeles", route.customerShipmentProcessedDateTimeUtc);
        if (shipDate.toDateStringTZ() === now.toDateStringTZ())
            return "Today";
        if (shipDate.toDateStringTZ() === tomorrow.toDateStringTZ())
            return "Tomorrow";
        return shipDate.toLocaleDateString("en-us", options);
    }
}
