import { Role } from "../models/Role";
import { authorizationNavigationGuard } from "./authorization-navigation-guard";
import { isInRole } from "../utilities";
import { globals } from "../utilities/globals";
import { default as Router } from "vue-router";
const routes = [
    {
        path: "/",
        component: () => import("../views/Layout.vue"),
        meta: { requiresAuth: true },
        children: [
            {
                path: "/",
                redirect: (to) => {
                    const { hash, params, query } = to;
                    if (isInRole([Role.Admin, Role.MemberAdmin]))
                        // Redirect customer-admin-only roles to alternate homepage
                        return {
                            hash, query, params, name: "customers"
                        };
                    return {
                        hash, query, params, name: "home"
                    };
                }
            },
            {
                path: "/",
                name: "home",
                component: () => import("../components/b2b/b2b-home.vue"),
                meta: {
                    role: [Role.User, Role.CustomerAdmin]
                }
            },
            {
                path: "/shop",
                name: "shop",
                component: () => import("../components/b2b/order/b2b-order.vue"),
                meta: {
                    role: [Role.User, Role.CustomerAdmin]
                }
            },
            {
                path: "/checkout",
                name: "b2b-checkout",
                component: () => import("../components/b2b/b2b-checkout.vue"),
                meta: {
                    role: [Role.User, Role.CustomerAdmin]
                }
            },
            {
                path: "/open-orders",
                name: "b2b-open-orders",
                component: () => import("../components/b2b/b2b-open-orders.vue"),
                meta: {
                    role: [Role.User, Role.CustomerAdmin]
                }
            },
            {
                path: "/order/report",
                name: "b2b-order-report",
                component: () => import("../components/b2b/b2b-order-report.vue")
            },
            {
                path: "/invoice",
                name: "b2b-invoice-history",
                component: () => import("../components/b2b/b2b-invoice-history.vue"),
                meta: {
                    role: [Role.User, Role.CustomerAdmin]
                }
            },
            {
                path: "/invoice/detail",
                name: "b2b-invoice-details",
                component: () => import("../components/b2b/b2b-invoice-details.vue"),
                meta: {
                    role: [Role.User, Role.CustomerAdmin]
                }
            },
            {
                path: "/companies",
                name: "b2b-companies",
                component: () => import("../components/b2b/b2b-companies.vue"),
                meta: {
                    role: [Role.Admin]
                }
            },
            {
                path: "/customers",
                name: "customers",
                component: () => import("../components/b2b/customers/b2b-customers.vue"),
                meta: {
                    role: [Role.Admin, Role.MemberAdmin]
                }
            },
            {
                path: "/customers/:wholesaleCustomerId(\\d+)",
                name: "edit-customer",
                props: (route) => ({ wholesaleCustomerId: parseInt(route.params.wholesaleCustomerId) }),
                component: () => import("../views/CustomerEdit.vue"),
                meta: {
                    role: [Role.Admin, Role.MemberAdmin]
                }
            },
            {
                path: "/customers/create",
                name: "create-customer",
                component: () => import("../views/CustomerCreate.vue"),
                meta: {
                    role: [Role.Admin, Role.MemberAdmin]
                }
            },
            {
                path: "/traction/credentials",
                name: "traction-credentials",
                component: () => import("../components/b2b/credentials/traction-api-credentials.vue"),
                meta: {
                    role: [Role.Admin, Role.MemberAdmin]
                }
            },
            {
                path: "/users",
                name: "user-management",
                component: () => import("../views/UserManagement.vue"),
                meta: {
                    role: [Role.Admin, Role.CustomerAdmin]
                }
            },
            {
                path: "/quotes",
                name: "quotes",
                component: () => import("../views/Quotes.vue"),
                meta: {
                    role: [Role.User, Role.CustomerAdmin]
                }
            },
            {
                path: "/quotes/price-quote",
                name: "price-quote",
                component: () => import("../components/PriceQuotePreview.vue"),
                meta: {
                    role: [Role.User, Role.CustomerAdmin]
                }
            },
            {
                path: "/quotes/settings",
                name: "quote-settings",
                component: () => import("../views/PriceQuoteSettings.vue"),
                meta: {
                    role: [Role.User, Role.CustomerAdmin]
                }
            },
            {
                path: "/help",
                name: "help",
                component: () => import("../views/Help.vue"),
                meta: {
                    role: [Role.User, Role.CustomerAdmin]
                }
            }
        ]
    }
];
export const createRouter = () => {
    const router = new Router({
        mode: "history",
        base: globals.BASE_URL,
        routes,
        scrollBehavior(_to, _from, savedPosition) {
            if (savedPosition) {
                return savedPosition;
            }
            else {
                return { x: 0, y: 0 };
            }
        }
    });
    // TODO: may still need part of this guard
    // If user is unauthenticated and route requiresAuth, send them to internal sign-in
    // router.beforeEach(signinNavigationGuard);
    // Check user is in any required roles, and belongs to the tenant for the current domain
    router.beforeEach(authorizationNavigationGuard);
    return router;
};
