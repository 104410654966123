import { globals } from "../utilities/globals";
let testDomain;
function getDomainNameProd() {
    return location.hostname;
}
function getDomainNameTest() {
    if (!testDomain)
        testDomain = new URLSearchParams(location.search).get("domain") || "www.tfdistro.com";
    return testDomain;
}
export const getDomainName = globals.NODE_ENV === "production" ? getDomainNameProd : getDomainNameTest;
