export var Role;
(function (Role) {
    Role["User"] = "B2BCustomerUser";
    Role["CustomerAdmin"] = "B2BCustomerAdmin";
    Role["MemberAdmin"] = "B2BMemberAdmin";
    Role["Admin"] = "PointSAdmin";
})(Role || (Role = {}));
export function getFriendlyRoleName(role) {
    switch (role) {
        case Role.User: return "User";
        case Role.CustomerAdmin: return "Admin";
        case Role.MemberAdmin: return "Member Admin";
        case Role.Admin: return "PointS Admin";
    }
}
