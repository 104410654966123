import { useMainStore } from "../store/main";
import { isInRole } from "../utilities";
import { useOidcStore } from "@points/common";
export async function authorizationNavigationGuard(to, _from, next) {
    const requiresAuth = to.matched.some((x) => x.meta?.requiresAuth || x.meta?.role);
    if (requiresAuth) {
        // Make sure auth is loaded or try to load it
        const oidcStore = useOidcStore();
        try {
            if (!oidcStore.isAuthenticated)
                await oidcStore.signInSilent();
        }
        catch {
            // If sign-in silent fails, ignore and kick to sign in page
        }
        if (!oidcStore.isAuthenticated)
            return await oidcStore.signIn();
        // Wait for storelocation and role information to be loaded
        const mainStore = useMainStore();
        await mainStore.getLoadWholesaleUserAwaitable();
        // Verify user has required role or forbid
        const roleRecords = to.matched.filter((record) => (Array.isArray(record.meta.role) && typeof record.meta.role[0] === "string" || typeof record.meta.role === "string"));
        if (roleRecords.length > 0) {
            if (roleRecords.some((record) => !isInRole(record.meta.role))) {
                return next({ name: "forbidden" });
            }
        }
        // Verify user belongs to the tenant of the current domain
        const userIsOnWrongSite = mainStore.wholesaleUser?.companyId !== mainStore.wholesaleCompany?.companyId;
        if (userIsOnWrongSite && to.name !== "forbidden")
            return next({ name: "forbidden" });
    }
    next();
}
